.criteria-container{
    border: 1px solid black;
}

.criteria-col{
    display: flex;
    justify-content: space-between;
}

.row-cont{
    margin-left: 70px;
    margin-right: 70px;
    border-radius: 5px;
}

.upper-row{
   background-color: rgb(243, 252, 252);
   padding: 20px;
   display: flex;
   justify-content: space-between;
}

.lower-row{
    background-color: black;
   padding: 25px;

}

.criteria-btn{
    border: 1px solid #fafbfd !important;
}

.criteria-btn > .button-text{
    color: white !important;
}


@media only screen and (min-width : 480px) {
    .profile-header {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    
    }
}

